import React, {useState} from "react"

import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../components/products.css"


import img1 from '../images/beczka-po-saunie/1.jpeg';
import img2 from '../images/beczka-po-saunie/2.jpeg';
import img3 from '../images/beczka-po-saunie/3.jpeg';
import img4 from '../images/beczka-po-saunie/4.jpeg';
import img5 from '../images/beczka-po-saunie/5.jpeg';
import img6 from '../images/beczka-po-saunie/6.jpeg';
import img7 from '../images/beczka-po-saunie/7.jpeg';
import img8 from '../images/beczka-po-saunie/8.jpeg';


import raty from '../images/raty-banner.jpg'

import ImageGallery from 'react-image-gallery';
 
const images = [
  {
    original: img1,
    thumbnail: img1,
  },
  {
    original: img2,
    thumbnail: img2,
  },
  {
    original: img3,
    thumbnail: img3,
  },
  {
    original: img4,
    thumbnail: img4,
  },
  {
    original: img5,
    thumbnail: img5,
  },
  {
    original: img6,
    thumbnail: img6,
  },
  {
    original: img7,
    thumbnail: img7,
  },
  {
    original: img8,
    thumbnail: img8,
  }
];

const ProductView15 = () => {

  const price = 3700;

  return(
  <Layout>
    <SEO 
      title="Jacuzzi" 
      description = { SEO.description }
      urlName="beczka-po-saunie"
    />
    <div className="banner">
        <div className="bannerPlate">
          <p>Beczka po saunie</p>
        </div>
    </div>
    <div className="productViewPlate">

      <div className="gallery">
        <ImageGallery slideInterval="2000" items={images} />
      </div>
      <div className="productDescription">
          <div id="prodTop"></div>
          <div id="prodLeft"></div>
          <div id="prodBottom"></div>
          <div id="prodRight"></div>
          <div id="productTitle">Beczka po saunie</div>
          <div id="productPrice">{price}zł
          </div>
          <div className="productOption">
              <div className="productDescriptionText">
                  <h3>Opis</h3>
                  <p>
                  Beczka po saunie idealnie sprawdzi się do schłodzenia naszego ciała po wyjściu z sauny ale doskonale sprawdzi się również do morsowania. Produkt posiada schodki aby ułatwić wchodzenie oraz pokrywę. Wnętrze beczki wykonane jest z żywicy epoksydowej co zapewnia wytrzymałość.<br/>
                  </p>
                  <h3>Dane techniczne:</h3>
                  <ul>
                    <li>Wysokość: 100cm</li>
                    <li>Szerokość: 100cm</li>
                    <li>Wewnętrzny wkład z żywicy epoksydowej</li>
                    <li>Pokrywa balii</li>
                    <li>Schodki</li>
                  </ul>
                  <a href="https://epos.inbank.pl/pl/drewhaus">
                  <img src={raty} className="creditBanner" alt="domek-ogrodowy-raty"/>
                  </a>
                  <p><b>Balie ogrodowe możesz obejrzeć w Gnieźnie! Zajrzyj do zakładki&nbsp; 
                    <Link to="/contact" style={{ color: '#765F52' }} >
                      kontakt
                    </Link> i sprawdź gdzie nas znajdziesz!</b></p>

              </div>
          </div>
      </div>
    </div>
  </Layout>
  )}

export default ProductView15


